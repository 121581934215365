<template>
  <b-card title="">
    <b-row class="justify-content-start d-flex">
      <b-tabs content-class="mt-3">
        <b-tab
          title="Ativos"
          @click="(depreciableAssetsList = false), getAllDepreciableAssets()"
          active
        >
        </b-tab>
        <b-tab
          title="Inativos"
          @click="(depreciableAssetsList = true), getAllDepreciableAssets()"
        >
        </b-tab>
      </b-tabs>
    </b-row>

    <b-row class="justify-content-between d-flex">
      <b-col>
        <b-row class="ml-2">
          <b-form-group>
            <b-form-input
              type="text"
              v-model="search"
              placeholder="Buscar item"
            ></b-form-input>
          </b-form-group>
        </b-row>
      </b-col>

      <b-col class="d-flex justify-content-end">
        <b-row>
          <b-button
            class="mb-2 mr-2"
            variant="primary"
            @click="openModalDefault"
          >
            Novo item +
          </b-button>
        </b-row>
      </b-col>
    </b-row>

    <b-table
      responsive="sm"
      small
      :items="filter()"
      :fields="fields"
      class="p-1 w-100"
    >
      <template v-slot:cell(actions)="{ item }">
        <ActionsTaxes
          @openModal="openEditModal(item)"
          @openModaDelete="openDeleteModal(item)"
          :item="item"
        />
      </template>
      <template v-slot:cell(acquisition_date)="{ item }">
        {{ dateFormatter(item.acquisition_date) }}
      </template>
      <template v-slot:cell(acquisition_value)="{ item }">
        {{ `R$ ${numberToMonetary(item.acquisition_value)}` }}
      </template>
      <template v-slot:cell(residual_value)="{ item }">
        {{ `R$ ${numberToMonetary(item.residual_value)}` }}
      </template>
      <template v-slot:cell(depreciable)="{ item }">
        {{
          ` - R$ ${numberToMonetary(
            Math.round((item.acquisition_value - item.residual_value) / 60)
          )}`
        }}
      </template>
    </b-table>
    <b-col cols="12" align="end">
        <b>Total dos bens: R$ {{numberToMonetary(totalAcquisitionValue()) }} </b>
      </b-col>
    <ModalTaxes
      :modalDepreciableAssets="modalDepreciableAssets"
      :modalDepreciableAssetsForm="modalDepreciableAssetsForm"
      :editable="editable"
      :itemUpdate="itemUpdate"
      @updateList="handleUpdateList"
      @renderList="getAllDepreciableAssets"
      @closeModal="closeModal()"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BTabs,
  BTab,
  BRow,
  BButton,
  BTable,
  BFormGroup,
  BFormInput,
  BCol,
  // BIconTrash,
} from 'bootstrap-vue';
import { VBTooltip } from 'bootstrap-vue';
import ActionsTaxes from './components/ActionsDepreciableAssets.vue';
import ModalTaxes from './components/ModalDepreciableAssets.vue';
export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BTabs,
    BTab,
    BTable,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // BIconTrash,
    ActionsTaxes,
    ModalTaxes,
  },
  data: () => ({
    modalDepreciableAssets: false,
    modalDepreciableAssetsForm: false,
    editable: false,
    itemUpdate: null,
    moneyMask: {
      prefix: 'R$ ',
      suffix: '',
      thousands: '.',
      decimal: ',',
      precision: 2,
      masked: false,
    },
    fields: [
      {
        key: 'actions',
        label: 'Ações',
        sortable: false,
      },
      {
        key: 'item_name',
        label: 'item',
        sortable: true,
      },
      {
        key: 'acquisition_date',
        label: 'data da aquisição',
        sortable: true,
      },
      {
        key: 'acquisition_value',
        label: 'valor de aquisição',
        sortable: true,
      },
      {
        key: 'residual_value',
        label: 'valor residual',
        sortable: true,
      },
      {
        key: 'lifespan_months',
        label: 'vida útil ( em meses )',
        sortable: true,
      },
      {
        key: 'depreciable',
        label: 'depreciação mensal',
        sortable: true,
      },
    ],
    items: [],
    depreciableAssetsList: false,
    search: '',
  }),

  created() {
    this.getAllDepreciableAssets();
  },

  computed: {
    currentWorkspace() {
      return this.$store.getters.currentWorkspace;
    },
    depreciableAssetsFilteres() {
      let values = [];
      values = this.items.filter((item) => {
        return (
          item.item_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        );
      });
      return values;
    },
  },

  methods: {
    getAllDepreciableAssets() {
      this.items = [];
      this.$store
        .dispatch('getAllDepreciableAssets', {
          inactive: this.depreciableAssetsList,
          workspace_id: this.$store.getters.currentWorkspace.id,
        })
        .then((resp) => {
          if (resp) {
            this.items = resp.Goods;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    totalAcquisitionValue() {
      return this.filter().reduce((total, item) => total + item.acquisition_value, 0);
    },

    filter() {
      if (this.search.trim() === '') {
        // If the search input is empty, return all items
        return this.items;
      } else {
        // If there's a search term, filter the items
        return this.items.filter((item) => {
          return (
            item.item_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
          );
        });
      }
    },

    cleanFilter() {
      this.search = '';
    },
    openEditModal(item) {
      this.modalDepreciableAssets = true;
      this.editable = true;
      this.modalOpenDelete = false;
      this.modalDepreciableAssetsForm = true;
      this.itemUpdate = item.id;
    },
    openDeleteModal(item) {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = false;
      this.modalOpenDelete = true;
      this.itemUpdate = item.id;
    },
    openModalDefault() {
      this.modalDepreciableAssets = true;
      this.modalDepreciableAssetsForm = true;
      this.modalOpenDelete = false;
      this.editable = false;
      // this.itemUpdate = item.id
    },
    closeModal() {
      this.modalDepreciableAssets = false;
      this.editable = false;
    },
    handleUpdateList() {
      this.getAllDepreciableAssets();
    },
  },
};
</script>
