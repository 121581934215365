var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',{attrs:{"title":""}},[_c('b-row',{staticClass:"justify-content-start d-flex"},[_c('b-tabs',{attrs:{"content-class":"mt-3"}},[_c('b-tab',{attrs:{"title":"Ativos","active":""},on:{"click":function($event){(_vm.depreciableAssetsList = false), _vm.getAllDepreciableAssets()}}}),_c('b-tab',{attrs:{"title":"Inativos"},on:{"click":function($event){(_vm.depreciableAssetsList = true), _vm.getAllDepreciableAssets()}}})],1)],1),_c('b-row',{staticClass:"justify-content-between d-flex"},[_c('b-col',[_c('b-row',{staticClass:"ml-2"},[_c('b-form-group',[_c('b-form-input',{attrs:{"type":"text","placeholder":"Buscar item"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1)],1),_c('b-col',{staticClass:"d-flex justify-content-end"},[_c('b-row',[_c('b-button',{staticClass:"mb-2 mr-2",attrs:{"variant":"primary"},on:{"click":_vm.openModalDefault}},[_vm._v(" Novo item + ")])],1)],1)],1),_c('b-table',{staticClass:"p-1 w-100",attrs:{"responsive":"sm","small":"","items":_vm.filter(),"fields":_vm.fields},scopedSlots:_vm._u([{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('ActionsTaxes',{attrs:{"item":item},on:{"openModal":function($event){return _vm.openEditModal(item)},"openModaDelete":function($event){return _vm.openDeleteModal(item)}}})]}},{key:"cell(acquisition_date)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormatter(item.acquisition_date))+" ")]}},{key:"cell(acquisition_value)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.acquisition_value))))+" ")]}},{key:"cell(residual_value)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(("R$ " + (_vm.numberToMonetary(item.residual_value))))+" ")]}},{key:"cell(depreciable)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((" - R$ " + (_vm.numberToMonetary( Math.round((item.acquisition_value - item.residual_value) / 60) ))))+" ")]}}])}),_c('b-col',{attrs:{"cols":"12","align":"end"}},[_c('b',[_vm._v("Total dos bens: R$ "+_vm._s(_vm.numberToMonetary(_vm.totalAcquisitionValue()))+" ")])]),_c('ModalTaxes',{attrs:{"modalDepreciableAssets":_vm.modalDepreciableAssets,"modalDepreciableAssetsForm":_vm.modalDepreciableAssetsForm,"editable":_vm.editable,"itemUpdate":_vm.itemUpdate},on:{"updateList":_vm.handleUpdateList,"renderList":_vm.getAllDepreciableAssets,"closeModal":function($event){return _vm.closeModal()}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }